import React from 'react';
import section5bg from '../../assets/frontend/desktop/section5bg.png';


const Section5 = () => {
  const containerStyle = {
    background: 'linear-gradient(to bottom, #99B3D6 0%, #5C87BA 50%, #99B3D6 100%)',
  };

  return (
    <section style={containerStyle} className="pt-16 pb-24">
      <div className="mx-32">
        <div className="flex justify-center items-center py-14">
          <div className="xl:w-full md:w-full xl:p-20 md:p-20 rounded-md section3card">
            <div className="md:flex md:items-center">
              {/* Left Column */}
              <div className="md:w-1/2 md:pr-8">
                <h2 className="text-2xl font-bold mb-2 section5title">
                   More impressions, more <br /> conversions
                </h2>
                <p className="text-gray-600 mb-4 mt-10">
                  Increasing the visibility of your content to a larger audience will naturally lead to a greater number of people taking the desired actions. The more people who see your content, the higher the chances of finding individuals who are interested and motivated enough to convert.
                </p>
              </div>

              {/* Right Column */}
              <div className="md:w-1/2 mt-4 md:mt-0 md:pl-8">
                <img src={section5bg} alt="Image" className="max-w-full h-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
