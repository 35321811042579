import React, {useState} from 'react';
import section1img from '../../assets/frontend/desktop/section1img.png';
import Popup from "../popup/Popup";

const Section1 = () => { 
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const openPopup = () => {
        setIsPopupOpen(true);
    };
    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className="relative mt-14 homesection">
            
            <div className="flex flex-col-reverse md:flex-row overflow-visible">
            <div className="flex-1 md:p-6 overflow-visible">
                <div className="m-5 md:m-20">
                    <h1 className="Section1maintxt mb-6 md:mb-16">
                        360 Sales Solutions
                    </h1>
                    <h2 className="Section1subtext my-6 md:my-10">An instant market evaluation tool that simplify <br />Decision making</h2>
                    <button onClick={openPopup} className="Section1btn text-white mb-6 md:mb-12 mt-4 md:mt-6">
                    Apply for demo
                </button>
                </div>
            </div>
            <div className="flex-1 overflow-visible">
                <div className="flex justify-center md:justify-end py-6 pt-16">
                    <img className="max-w-full h-auto md:max-w-lg xl:max-w-3xl" src={section1img} alt="Responsive" />
                </div>
            </div>
        </div>

        <div className="flex flex-col md:flex-row items-center ml-0 md:ml-24 pb-6 md:pb-16 space-y-4 md:space-y-0 space-x-0 md:space-x-10 -mt-6 md:-mt-10 overflow-visible">
            <div className="border-l-4 border-violet-600 p-1 md:p-2 h-2 flex items-center">
                <span className="font-extrabold text-xs md:text-sm">36K+ Users</span>
            </div>
            <div className="border-l-4 border-yellow-400 p-1 md:p-2 h-2 flex items-center">
                <span className="font-extrabold text-xs md:text-sm">58K+ Stores</span>
            </div>
            <div className="border-l-4 border-blue-300 p-1 md:p-2 h-2 flex items-center">
                <span className="font-extrabold text-xs md:text-sm">20k+ Downloads</span>
            </div>
        </div>
        
        {isPopupOpen && <Popup onClose={closePopup} />}

        </div>
    );
}

export default Section1;
