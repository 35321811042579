import React from 'react';
import "../../assets/css/desktop.css"
import Section1 from '../sections/section1';
import Section2 from '../sections/section2';
import Section3 from '../sections/section3';
import Section4 from '../sections/section4';
import Section5 from '../sections/section5';
import Section6 from '../sections/section6';
import Footer from '../shared/frontend/footer';
import Header from '../shared/frontend/header';

const Home = () => {
  const containerStyle = {
    position: 'relative', // Make the container a positioning context
  };

  const backgroundOverlayStyle = {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to right, #465687 0%, #8bc9de 100%)',
    opacity: 0.6, // Adjust the opacity as needed
    pointerEvents: 'none', // Let the content beneath be clickable
    zIndex: -1, // Place the overlay behind the content
  };

  return (
    <div style={containerStyle}>
      <Header />
      <div style={backgroundOverlayStyle}></div> {/* Background overlay */}
      <Section1 />
      <div className="mainsection">
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
