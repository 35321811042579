/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState,useEffect  } from 'react';
import Default from '../../assets/frontend/images/animation/Octagon1.svg';
import Variant2 from '../../assets/frontend/images/animation/Octagon2.svg';
import sectiontop from '../../assets/frontend/images/sectiontop.svg';
import sectiontbottom from '../../assets/frontend/images/sectiontbottom.svg';

const Section2 = () => {

    const [currentImage, setCurrentImage] = useState(Default);
    const [imageOpacity, setImageOpacity] = useState(1);
  
    useEffect(() => {
      // Change the image source and animate opacity smoothly
      const interval = setInterval(() => {
        setImageOpacity(0.10); // Set lower opacity during transition
        setTimeout(() => {
          setCurrentImage(currentImage === Default ? Variant2 : Default);
          setImageOpacity(0.20); // Fade in
        }, 5); // Wait for 500ms before changing image and fading in
      }, 1000); // Change image every 3 seconds
  
      return () => clearInterval(interval);
    }, [currentImage]);

    return (
        <div className='relative' id='Aboutus-section'>

            <div className="flex flex-col items-center justify-center"> 
                
                <h1 className="text-2xl md:text-4xl font-light mb-4 text-white mt-16">
                    About Us
                </h1>

                {/* Text on two lines */}
                <p className="text-center mb-8 mt-5 sectiontext" style={{ zIndex: 2 }}>
                    EXECUTIVE SOLUTIONS MARKETING RESEARCH)
                </p>               
           
                </div>


            <div className='relative'>
    
            <div className="image-container">
                <img src={currentImage}
                alt="Current Octagon"
                style={{ opacity: imageOpacity, transition: 'opacity 0.5s ease-in-out' }} />
            </div>


                <div className="px-5 md:px-10 mx-auto overflow-hidden bluecard mt-20 md:w-3/4" style={{ background: '#2C296B' }}>
                    <img src={sectiontop} alt="sectiontop" className='-mt-10 -ml-10' style={{ position: 'absolute', top: 0, left: 0 }} />

                    <div className="p-10 md:p-12 text-center">
                    
                        <h1 className="text-4xl md:text-4xl font-bold mb-10 text-white mt-2 md:mt-4">DATA-DRIVEN DECISIONS</h1>
                        <p className="text-gray-500 mx-4 my-14 md:my-14 text-left mx-14 font-light" style={{ fontSize : '16px', lineHeight: '2.5'}}>
                            With the rapid pace of digital innovation and big data availability, fast development makes it harder and challenging to follow and prioritize.
                            Business Executives understand the importance and the need of Digital Innovation, Artificial Intelligent and Big Data. But often, they don't know where to start and what is possible.
                            ESMR are here to help as the expert partner to start the journey by combining deep business and industry insight with digital innovation and big data collection.
                            Our teams of creative, industry and technology professional advisers will help you accelerate the impact digital solutions can have on your business.
                        </p>

                       <h2 className='text-xl py-5' style={{ color : '#8DD7EF'}}>FAREWELL TO DISTRACTED DATA, WELCOME TO DATA-DRIVEN CALMNESS</h2>

                    </div>

                    <img src={sectiontbottom} alt="sectiontbottom" className='sectiontbottom' style={{ position: 'absolute', bottom: 0, right: 0 }} />

               </div>
              
            </div>



        </div>
    );
};

export default Section2;