import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

function Popup({ onClose }) {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                const file = acceptedFiles[0];
                const allowedFileTypes = [
                    'image/jpeg',
                    'image/png',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                ];

                if (allowedFileTypes.includes(file.type)) {
                    setSelectedFile(file.name); // Set only the file name
                } else {
                    // Display an error message to the user about unsupported file type
                    console.error('Unsupported file type');
                }
            }
        },
    });


    useEffect(() => {
    // Fetch countries data from the API
    fetch('https://esmr-api.talentsgates.website/api/countries')
      .then(response => response.json())
      .then(data => {
        const filteredCountries = data.data.countries.filter(country => country.name !== 'string');
        const countryOptions = filteredCountries.map(country => ({
          id: country._id,
          name: country.name
        }));
        setCountries(countryOptions);
      })
      .catch(error => {
        console.error('Error fetching countries:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch cities data based on the selected country
      fetch(`https://esmr-api.talentsgates.website/api/cities/get_by_country/${selectedCountry}`)
        .then(response => response.json())
        .then(data => {
          const cityOptions = data.data.map(city => ({
            id: city._id,
            name: city.name
          }));
          setCities(cityOptions);
        })
        .catch(error => {
          console.error('Error fetching cities:', error);
        });
    }
  }, [selectedCountry]);

  const [touchedFields, setTouchedFields] = useState({
    termsChecked: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    compnayemail: '',
    companyphoneNumber: '',
      companymessage: '',
    termsChecked: false,
  });
  const [formErrors, setFormErrors] = useState({
    fullName: '',
    companyName: '',
    compnayemail: '',
    companyphoneNumber: '',
      companymessage: '',
    termsChecked: '',
  });

  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true,
    }));

    let error = '';

    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    } else {
      if (name === 'fullName') {
        error = value.trim() === '' ? 'Full Name is required' : '';
      } else if (name === 'companyName') {
        error = value.trim() === '' ? 'Company Name is required' : '';
      } else if (name === 'compnayemail') {
        error = !/\S+@\S+\.\S+/.test(value) ? 'Invalid email format' : '';
      } else if (name === 'companyphoneNumber') {
        error = !/^\d+$/.test(value) ? 'Invalid phone number format' : '';
      } else if (name === 'companymessage') {
        error = value.trim() === '' ? 'Message is required' : ''; // Updated error message
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const newErrors = {
      fullName: formData.fullName.trim() === '' ? 'Full Name is required' : '',
      companyName: formData.companyName.trim() === '' ? 'Company Name is required' : '',
      compnayemail: !/\S+@\S+\.\S+/.test(formData.compnayemail) ? 'Invalid email format' : '',
      companyphoneNumber: !/^\d+$/.test(formData.companyphoneNumber) ? 'Invalid phone number format' : '',
      termsChecked: formData.termsChecked === false ? 'Please accept the terms' : '',
        companymessage: formData.companymessage.trim() === '' ? 'Message is required' : '', // Updated error message
    };

    setFormErrors(newErrors);

    const isFormValid = Object.values(newErrors).every((error) => error === '');

    if (isFormValid) {
      try {
        // Prepare the data for API call
        const dataToSend = {
          full_name: formData.fullName,
          company_name: formData.companyName,
          phone_number: formData.companyphoneNumber,
          email: formData.compnayemail,
          country: selectedCountry,
          city: selectedCity,
          file: selectedFile,
          message: formData.companymessage
        };

        // Send the data to the API endpoint
        const response = await fetch('https://esmr-api.talentsgates.website/api/esmrs/apply_for_demo', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataToSend)
        });

        // Handle the response here (e.g., show success message, close popup, etc.)
        if (response.ok) {
            // Handle success
            console.log('Submission successful');
            setIsSubmitting(false);
    
            // Clear form inputs
            setFormData({
              fullName: '',
              companyName: '',
              compnayemail: '',
              companyphoneNumber: '',
                companymessage: '',
              termsChecked: false,
            });
    
            // Close the popup
            onClose();
    
            // Show toast message
            toast.success('Submission successful!', {
              position: 'top-center',
              autoClose: 3000, // Adjust as needed
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
            });
          } else {
            // Handle error
            console.error('Submission failed');
            setIsSubmitting(false);
          }
        } catch (error) {
          console.error('API error:', error);
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
      }
    };

     return (

        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 backdrop-blur-md bg-gray-900">
        <div className="bg-white px-4 pb-6 md:px-12 md:pb-10 shadow-lg w-full md:w-2/3 relative popupdesgin">
                <button onClick={onClose}
                        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none">
                    <span className="sr-only">Close</span>
                    <svg className="h-10 w-10 font-extrabold text-black btnclose" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ margin: '25px' }}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                <h2 className="text-xl font-semibold mb-4 md:mb-6 text-center maintxt pt-16">Apply for <span className='submaintxt'>Demo</span> </h2>
                <p className="text-center mb-2 md:mb-4 subtxt mb-8">Experience ESMR: Request a Demo Today!.</p>

                <form>                  

                 <div className="md:flex md:mb-10 md:space-x-4 space-y-4 md:space-y-0">
                        <div className="w-full">
                        <label className="block text-blue-950 text-xs">FullName</label>
                        <input
                            type="text"
                            className="border-none w-full py-2 inp"
                            placeholder="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.fullName && <p className="text-red-500 text-xs mt-1">{formErrors.fullName}</p>}
                        </div>

                        <div className="w-full">
                        <label className="block text-blue-950 text-xs">Company Name</label>
                        <input
                            type="text"
                            className="border-none w-full py-2 inp"
                            placeholder="Company Name"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                        {formErrors.companyName && <p className="text-red-500 text-xs mt-1">{formErrors.companyName}</p>}
                        </div>
                    </div>


                        <div className="mb-4 md:flex">                    
                            <select className="w-full border-none inp p-2 mb-2 md:mb-0 md:mr-2"
                                value={selectedCountry}
                                onChange={(e) => setSelectedCountry(e.target.value)}>
                                <option value="">Select a country</option>
                                {countries.map(country => (
                                <option key={country.id} value={country.id}>
                                    {country.name}
                                </option>
                                ))}
                            </select>
                            <select
                                className="w-full border-none inp p-2 md:ml-2"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                            >
                                <option value="">Select a city</option>
                                {cities.map(city => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>

                        </div>
        
                        <div className="md:flex md:mb-10 md:space-x-4 space-y-4 md:space-y-0">
                            <div className="w-full">
                            <label className="block text-blue-950 text-xs">Email</label>
                            <input
                                type="text"
                                className="border-none w-full py-2 inp"
                                placeholder="Email"
                                name="compnayemail"
                                value={formData.compnayemail}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.compnayemail && <p className="text-red-500 text-xs mt-1">{formErrors.compnayemail}</p>}
                            </div>

                            <div className="w-full">
                            <label className="block text-blue-950 text-xs">phone Number</label>
                            <input
                                type="text"
                                className="border-none w-full py-2 inp"
                                placeholder="phone Number"
                                name="companyphoneNumber"
                                value={formData.companyphoneNumber}
                                onChange={handleChange}
                                required
                            />
                            {formErrors.companyphoneNumber && <p className="text-red-500 text-xs mt-1">{formErrors.companyphoneNumber}</p>}
                            </div>
                        </div>


                    <div className="mb-4">
                        <textarea
                            className="w-full border-none inp p-2 txtarea"
                            placeholder="Contact us..."
                            rows="4"
                            name="companymessage"
                            value={formData.companymessage}
                            onChange={handleChange}
                            required
                        ></textarea>
                        {formErrors.companymessage && <p className="text-red-500 text-xs mt-1">{formErrors.companymessage}</p>}
                    </div>

                    <div className="mb-4">
                        <div {...getRootProps()} className="mb-4 text-center p-8 cursor-pointer uploadarea">
                            <input {...getInputProps()} />
                            <p className='Inerlblupload'>Upload Additional file</p>
                        </div>
                        {selectedFile && (
                            <p>Selected File: {selectedFile}</p>
                            )}
                    </div>
                    <p className='lblupload ml-4'>Attach file. File size of your documents should not exceed 10MB</p>                    

                    <div className="flex items-center my-4 text-sm font-light">
                       
                         {/*
                            <button onClick={onClose} >
                                Apply
                            </button>
                         */}

                         <input
                           className="mr-2"
                           type="checkbox"
                           id="terms"
                           name="termsChecked"
                           checked={formData.termsChecked}
                           onChange={handleChange}
                         />
                         <label htmlFor="terms" className='terms'>I want to protect my data by signing an NDA.</label>
                         {formErrors.termsChecked && (
                           <span className="text-red-500 mx-10">{formErrors.termsChecked}</span>
                         )}
                       </div>
             
                       <div className="flex justify-center">
                         <button
                           className="px-4 py-2 bg-primary text-white rounded-full hover:bg-hover-buttonbg btnsubmit"
                           type="button"
                           onClick={handleSubmit}
                           disabled={isSubmitting}
                         >
                           {isSubmitting ? 'Apply......' : 'Send Message'}
                         </button>


                    </div>                    

                </form>


            </div>
        </div>
    );
}

export default Popup;
