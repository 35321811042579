import { Outlet } from "react-router-dom"
import Header from './../../shared/frontend/header';

const User = () => {
  return (
    <div>   
      <Outlet />
  </div>
  );
};

export default User;