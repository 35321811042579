/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import footerlogo from '../../../assets/frontend/images/footerlogo.png';

const Footer = () => {
    return (
        <footer className="left-0 w-full bg-footer-bg bg-cover bg-center text-white py-8">
            <div className="flex flex-col md:flex-row justify-around items-center">

                <div className="mb-4 md:mb-0 mx-24 my-10">
                    <div className="mb-10">
                        <h3 className="text-xl mb-2 text-primary">Contact</h3>
                        <p className="ptext">Email: info@esmr.com</p>
                        <p className="ptext">Phone: +9665 667 66201</p>
                        <p className="ptext">Location: Saudi Arabia, Jeddah</p>
                    </div>
                    <div className="mb-10">
                        <h3 className="text-xl mb-2 text-primary">ESMR</h3>
                        <p className="ptext">
                            Helps people transform data into actionable insights.                            
                        </p>
                        <p className="ptext">
                            Explore with limitless visual analytics.
                        </p>
                    </div>
                    <div className="mb-10">
                        <p className="font-light mb-2 text-primary text-opacity-10">© 2023 ESMR. All Rights Reserved.</p>                       
                    </div>
                </div>


                <div className="mb-4 md:mb-0 mx-24 my-10">
                    <div className="mb-10">
                        <h3 className="text-xl mb-2 text-primary">Address</h3>
                        <p className="ptext">Saudi Arabia,</p>
                        <p className="ptext">JeddahAsh Shati Dist,</p>
                        <p className="ptext">Taha Khasiyfan .St</p>
                    </div>
                    <div className="mb-10">
                        <h3 className="text-xl mb-2 text-primary">Social</h3>
                        <p className="ptext">
                            Twitter
                        </p>
                        <p className="ptext">
                            Instagram
                        </p>
                        <p className="ptext">
                            Facebook
                        </p>
                    </div>                   
                </div>               

                <div className="md:ml-auto">
                    <img src={footerlogo} alt="Logo" className="w-52 h-22 mx-24 mt-40" />
                </div>
            </div>

          <a href='' alt='5sense'>
            <p className='font-light mb-2 text-primary text-opacity-60 text-center font-cairo'>© 2023 This site have been devloped by 5sense company</p>
          </a> 

        </footer>
    );
};

export default Footer;
