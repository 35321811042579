import React from 'react';
import "../../assets/css/style.css"
import "../../assets/css/mediaqueries.css"
import "../../assets/css/keyframes.css"
import Banner from '../products/banner';
import Section2 from '../products/section2';
import Section3 from "../products/section3";
import Contactus from "../products/contactus";
import Section4 from "../products/section4";
import Benefits from "../products/benefits";
import Section6 from "../products/section6";
import Header from '../shared/products/header';
import Footer from "../shared/products/footer";

const Products = () => {
    return (
        <div className='relative'>
            <Header />
            <div className='general'>
                <Banner />
                <Section2 />
                <Section3 />
                <Section4 />
                <Benefits />
                <Section6 />
                <Contactus />
                <Footer />
            </div>
        </div>
    );
};

export default Products;
