/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';
import bannerImgSrc from '../../assets/frontend/images/animation/Section1a.svg';
import section1bImgSrc from '../../assets/frontend/images/animation/Section1b.svg';
import {Link} from "react-scroll";
import {useNavigate} from "react-router-dom";

const Banner = () => {
  
    const [showDefault, setShowDefault] = useState(true);
    const handleMouseEnter = () => {
        setShowDefault(false);
    };

    const handleMouseLeave = () => {
        setShowDefault(true);
    };

    const navigate = useNavigate();
    const goToDesktopPage = () => {
        navigate('/desktop'); // Navigate to the "desktop" page
    };

    return (
        <div className="Section1 pt-20" id='Home-section'>
            <div className="flex flex-col items-center justify-center">
                {/* Button at the top center */}

                <button onClick={goToDesktopPage}
                        className="my-10 inline-flex items-center py-2 px-6 text-primary bg-transparent border border-1 border-primary rounded-full hover:bg-buttonbg hover:text-primary hover:border-primary">
                        We are Launching soon..
                </button>

                {/* Head title */}
                <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold mb-4 gradient-text" style={{ zIndex: 2 }}>
                    27 days 03 hours 46 mins
                </h1>

                {/* Text on two lines */}
                <p className="text-center mb-8 mt-5 sectiontext" style={{ zIndex: 2 }}>
                    EXECUTIVE SOLUTIONS MARKETING RESEARCH (ESMR)
                    <br />
                    <span className='datadriven'>Data Driven Actions.</span>
                </p>

                {/* Two buttons in the middle */}
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4" style={{ zIndex: 2 }}>
                    <button className="w-full md:w-auto px-4 py-2 text-black btnapply">
                        Apply For a Demo
                    </button>
                    <button className="w-full md:w-auto px-4 py-2 text-black btncontact">
                        Contact Us
                    </button>
                </div>

                {/* Responsive image */}
                <div className="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
               <img
                   className="my-8 max-w-full h-auto"
                   src={showDefault ? bannerImgSrc : section1bImgSrc}
                   alt="Responsive Image"
                   style={{ zIndex: 1 }}
               />
           </div>

            </div>
        </div>
    );
};

export default Banner;
