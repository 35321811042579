/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import triangel1 from '../../assets/frontend/images/animation/triangel1.svg';
import triangel2 from '../../assets/frontend/images/animation/triangel2.svg';
import graph from "../../assets/frontend/images/graph.svg";
import anaylses from "../../assets/frontend/images/anaylses.svg";
import yesno from "../../assets/frontend/images/yesno.svg";
import sectiontbottom from "../../assets/frontend/images/section4b.svg";

const Section4 = () => {

    const [currentImage, setCurrentImage] = useState(triangel1);

    useEffect(() => {
      const animationInterval = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage === triangel1 ? triangel2 : triangel1));
      }, 800); // Change image every 3 seconds
  
      return () => clearInterval(animationInterval);
    }, []);

    return (
        <div className="relative my-24" id='services-section'>

            <div className="flex flex-col items-center justify-center">

                <h1 className="text-3xl md:text-4xl font-light mb-4 text-white mt-16">
                    What we Do ?
                </h1>

                {/* Text on two lines */}
                <p className="text-center mb-6 mt-2 sectiontext" style={{ zIndex: 2 }}>
                    Check out our ESMR Technologies
                </p>

            </div>

            <div className='relative'>

                <div className="px-5 md:px-10 mx-auto overflow-hidden bluecard mt-20 md:w-3/4" style={{ background: '#2C296B' }}>

                    <div className="p-5 md:p-12 text-center">

                        <h1 className="text-4xl md:text-4xl font-bold mb-2 text-white mt-2 md:mt-4">ESMR Technologies</h1>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                            <div className="p-2 md:p-2 text-center">
                                <p className="text-white opacity-60 my-6 md:my-6 text-left mx-2 font-light" style={{ fontSize: '16px', lineHeight: '2.7' }}>
                                        ESMR technologies help clients explore big data with limitless visual analytics by building dashboards and performing ad hoc analyses in just a few clicks.
                                        ESMR technologies are based on AI engine that develops algorithms which are trained to use big data to make relatively complex predictions and suggested decisions.
                                        Such engine can recognize images, decipher text, segment customers, and
                                        anticipate how customers will respond to various initiatives, such as promotions and voluminous amounts of data.
                                        Our clients can use our ESMR technologies to visualize and better understand the market data and make an impact on the business.

                                </p>
                            </div>

                            <div className="flex justify-center items-center md:justify-start md:items-center p-6 md:p-16">
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">

                                    {/* First Row */}
                                    <div className="col-span-1 md:col-span-2 w-full md:w-1/2">
                                        <div className='column'>
                                            <h1 className='columnh'>SET</h1>
                                            <p className="columnp">
                                                the short- and long-term goals of your company
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-1 flex justify-center items-center">
                                        <img src={graph} alt="Graph" className="h-10 w-10 md:h-12 md:w-12" />
                                    </div>

                                    {/* Second Row */}
                                    <div className="col-span-1 md:col-span-2 w-full md:w-1/2 flex justify-center items-center">
                                        <img src={anaylses} alt="Graph" className="h-10 w-10 md:h-12 md:w-12" />
                                    </div>
                                    <div className="col-span-1 md:col-span-1 flex justify-center items-center">
                                        <div className='column'>
                                            <h1 className='columnh'>ANALYSE</h1>
                                            <p className="columnp">
                                                the big data using ESMR technologies
                                            </p>
                                        </div>
                                    </div>

                                    {/* Third Row */}
                                    <div className="col-span-1 md:col-span-2 w-full md:w-1/2">
                                        <div className='column'>
                                            <h1 className='columnh'>TAKE</h1>
                                            <p className="columnp">
                                                the right and trusted decisions
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-span-1 md:col-span-1 flex justify-center items-center">
                                        <img src={yesno} alt="Graph" className="h-10 w-10 md:h-12 md:w-12" />
                                    </div>

                                </div>
                            </div>

                        </div>

                        <h2 className='text-xl py-5' style={{ color: '#8DD7EF' }}>
                            USING THE RIGHT TECHNOLOGIES TO MAKE THE RIGHT DECISIONS
                        </h2>

                    </div>

                    <img src={sectiontbottom} alt="sectiontbottom" className='sectiontbottom' style={{ position: 'absolute', bottom: 0, left: 0 }} />

                </div>

            </div>


            <div className="image-animation">
            <img
              src={triangel1}
              alt="Triangle 1"
              className={`animated-image ${currentImage === triangel1 ? 'visible' : 'hidden'}`}
              style={{ opacity: 0.3 }} // Adjust this value for transparency
            />
            <img
              src={triangel2}
              alt="Triangle 2"
              className={`animated-image ${currentImage === triangel2 ? 'visible' : 'hidden'}`}
              style={{ opacity: 0.3 }} // Adjust this value for transparency
            />
          </div>
          
          

        </div>
        

       
    );
};

export default Section4;
