import React, { useState } from 'react';
import validator from 'validator';
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";

/*phoneNumber: touchedFields.phoneNumber && !validator.isMobilePhone(formData.phoneNumber, 'any', { strictMode: true }) ? 'Invalid phone number format' : '',*/
const Contactus = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    subject: false,
    message: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [name]: true,
    }));

    // Perform validation based on the field name
    let error = '';
    if (name === 'firstName') {
      error = validator.isEmpty(value) ? 'First name is required' : '';
    } else if (name === 'lastName') {
      error = validator.isEmpty(value) ? 'Last name is required' : '';
    } else if (name === 'email') {
      error = !validator.isEmail(value) ? 'Invalid email format' : '';
    } else if (name === 'phoneNumber') {
      error = !/^\d+$/.test(value) ? 'Invalid phone number format' : '';
    } else if (name === 'subject') {
      error = validator.isEmpty(value) ? 'Subject is required' : '';
    } else if (name === 'message') {
      error = validator.isEmpty(value) ? 'Message is required' : '';
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Set submission state to true

    // Perform validation
    const newErrors = {
      firstName: validator.isEmpty(formData.firstName) ? 'First name is required' : '',
      lastName: validator.isEmpty(formData.lastName) ? 'Last name is required' : '',
      email: !validator.isEmail(formData.email) ? 'Invalid email format' : '',
      phoneNumber: !/^\d+$/.test(formData.phoneNumber) ? 'Invalid phone number format' : '',
      subject: validator.isEmpty(formData.subject) ? 'Subject is required' : '',
      message: validator.isEmpty(formData.message) ? 'Message is required' : '',
    };

    // Update the errors state
    setErrors(newErrors);

    // Check if the form is valid
    const isFormValid = Object.values(newErrors).every((error) => error === '');

    if (isFormValid) {
      console.log('Form Data:', formData);

      try {
        // Send the form data to the API using Fetch
        const response = await fetch('https://esmr-api.talentsgates.website/api/contact_us_esmrs/', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone_number: formData.phoneNumber,
            email: formData.email,
            Subject: formData.subject,
            message: formData.message,
          }),
        });

        if (response.ok) {
          const responseData = await response.json();
          toast.success('Form data sent successfully');
          setIsSubmitting(false); // Reset submission state
          setFormData({ // Clear form data
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: '',
          });
        } else {
          toast.error('Failed to send form data to the API');
          setIsSubmitting(false); // Reset submission state
        }
      } catch (error) {
        console.error('An error occurred while sending form data:', error);
        toast.error('An error occurred while sending form data');
        setIsSubmitting(false); // Reset submission state
      }
    } else {
      toast.error('Form validation failed. Please check your inputs.');
      setIsSubmitting(false); // Reset submission state
    }
  };


  return (
    <div id='Contactus-section' className='relative'>

      <div className="elipscontactus"></div>
      <div className="elipscontactus2"></div>

      <div className="flex flex-col w-3/4 mx-auto my-14">
        <h1 className="mt-16 text-white text-5xl text-center mb-8 font-normal" style={{ fontFamily: 'Poppins'  }}>Contact Us</h1>

        <div className="bg-white rounded-xl p-2 mb-10 mt-6">
        <div className="grid md:grid-cols-3 gap-4">
          {/* map side alaa */}
          <div className="col-span-3 md:col-span-1 bg-white">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59352.696765546025!2d39.11145236760928!3d21.603733304997945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3dade64b5f9b9%3A0x5acb46f4a9be8cda!2sAsh%20Shati%2C%20Jeddah%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1691447341679!5m2!1sen!2seg"
              width="100%"
              height="100%"
              allowFullScreen=""
              className="rounded-tl-xl rounded-bl-xl"
              loading="lazy"
            >
            </iframe>
          </div>
      
          {/* contact us */}
         
          <div className="col-span-3 md:col-span-2 bg-white rounded-xl p-6 md:p-14">

            <form>

              {/* First Name and Last Name */}
              <div className="md:flex md:mb-10 md:space-x-4 space-y-4 md:space-y-0">
                <div className="w-full">
                  <label className="block text-blue-950 text-xs">First Name</label>
                  <input
                      type="text"
                      className="border-none w-full py-2 inp"
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                  />
                  {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
                </div>

                <div className="w-full">
                  <label className="block text-blue-950 text-xs">Last Name</label>
                  <input
                      type="text"
                      className="border-none w-full py-2 inp"
                      placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                  />
                  {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
                </div>
              </div>

              {/* Email and Phone Number */}
              <div className="md:flex md:mb-10 md:space-x-4 space-y-4 md:space-y-0">
                <div className="w-full">
                  <label className="block text-blue-950 text-xs">Email</label>
                  <input
                      type="text"
                      className="border-none w-full py-2 inp"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                  />
                  {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                </div>

                <div className="w-full">
                  <label className="block text-blue-950 text-xs">Phone Number</label>
                  <input
                      type="text"
                      className="border-none w-full py-2 inp"
                      placeholder="Phone Number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                  />
                  {errors.phoneNumber && <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>}
                </div>
              </div>


              {/* Select Subject */}
              <div className="mb-10">
                <label className="block text-blue-950 text-sm">Select Subject?</label>
                <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-12 mt-3">
                  <label className="flex items-center">
                    <input
                        type="radio"
                        name="subject"
                        value="Inquiry"
                        className="form-radio text-blue-500"
                        onChange={handleChange}
                    />
                    <span className="ml-2 text-xs">General Inquiry</span>
                  </label>
                  <label className="flex items-center">
                    <input
                        type="radio"
                        name="subject"
                        value="DemoRequest"
                        className="form-radio text-blue-500"
                        onChange={handleChange}
                    />
                    <span className="ml-2 text-xs">Product Demo Request</span>
                  </label>
                </div>
                {errors.subject && <p className="text-red-500 text-xs mt-1">{errors.subject}</p>}
              </div>

              {/* Message */}
              <div className="mb-6">
                <label className="block text-gray-600">Message</label>
                <textarea
                    className="border-none w-full py-2 inp"
                    rows="4"
                    name="message"
                    placeholder="Write your message.."
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
              </div>

              {/* Send Message Button */}
              <div className="text-right">
                <button
                    className={`bg-blue-950 px-8 text-white py-2 rounded`}
                    type="button"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Send Message'}
                </button>
              </div>
            </form>

          </div>

        </div>
      </div>
      
        </div>

      <div className="s7estar1"></div>

    </div>
  );
};

export default Contactus;






