import React from 'react';
import footerlogo from '../../../assets/frontend/images/footerlogo.png';
import social3 from "../../../assets/frontend/images/social3.svg";
import social2 from "../../../assets/frontend/images/social2.svg";
import social1 from "../../../assets/frontend/images/social1.svg";
import phone from "../../../assets/frontend/images/icon/phone.svg";
import email from "../../../assets/frontend/images/icon/email.svg";
import location from "../../../assets/frontend/images/icon/location.svg";


const Footer = () => {
    return (
        <footer className="left-0 w-full bg-footer-bg bg-cover bg-center text-white py-8" style={{ background: 'linear-gradient(to right, #1C2350 , #314f70 60%, #1a3e64)' }}>
            <div className="flex flex-col md:flex-row justify-around items-center">

                <div className="mb-4 md:mb-0 mx-24 my-10">
                    <div className="mb-10">
                        <h3 className="text-xl mb-2 text-primary">Contact</h3>
                        <div className="flex items-center ptext my-3">
                            <img src={email} alt="Email Icon" className="w-6 h-6 mr-2" />
                            <p>info@esmr.com</p>
                        </div>
                        <div className="flex items-center ptext mb-3">
                            <img src={phone} alt="Phone Icon" className="w-6 h-6 mr-2" />
                            <p>+966 011 245 1175</p>
                        </div>
                        <div className="flex items-center ptext">
                            <img src={location} alt="Location Icon" className="w-6 h-6 mr-2" />
                            <p>Saudi Arabia, Jeddah</p>
                        </div>
                    </div>
                    <div className="mb-10">
                        <h3 className="text-xl mb-2 text-primary">ESMR</h3>
                        <p className="ptext">
                            Helps people transform data into actionable insights.                            
                        </p>
                        <p className="ptext">
                            Explore with limitless visual analytics.
                        </p>
                    </div>
                    <div className="mb-10">
                         <p className="font-light mb-2 text-primary text-opacity-40">© 2023 ESMR. All Rights Reserved.</p>
                        <p className="font-light mb-2 text-primary text-opacity-40">Designed and developed by : 5 Senses Smart Solutions</p>
                   </div>
                </div>

                <div className="mb-4 md:mb-0 mx-24 my-10">
                <div className="mb-10">
                    <h3 className="text-xl mb-2 text-primary">Address</h3>
                    <p className="ptext">Saudi Arabia,</p>
                    <p className="ptext">JeddahAsh Shati Dist,</p>
                    <p className="ptext">Taha Khasiyfan .St</p>
                </div>
                <div className="mb-10">
                    <h3 className="text-xl mb-2 text-primary">Social</h3>
                        <div className="flex">
                        <img
                            src={social3}
                            alt="Twitter Logo"
                            className="mr-4"
                        />
                        <img
                            src={social2}
                            alt="Instagram Logo"
                            className="mr-4"
                        />
                        <img
                            src={social1}
                            alt="Facebook Logo"
                        />
                    </div>
                </div>
            </div>
         

                <div className="md:ml-auto">
                    <img src={footerlogo} alt="Logo" className="w-52 h-22 mx-24 mt-40" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
