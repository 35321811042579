/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import salesSolution from '../../assets/frontend/images/SalesSolution.svg';
import triangel1 from '../../assets/frontend/images/animation/triangel1.svg';
import triangel2 from '../../assets/frontend/images/animation/triangel2.svg';


const Section5 = () => {

  const [currentImage, setCurrentImage] = useState(triangel1);

  useEffect(() => {
    const animationInterval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === triangel1 ? triangel2 : triangel1));
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(animationInterval);
  }, []);

  return (
    <div className="px-4 sm:px-8 lg:px-16 xl:px-20 mt-24 relative" id='Products-section'>
      <div className="flex flex-col items-center justify-center my-16">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-light mb-4 text-white mt-16">
           Our Products
        </h1>

        {/* Text on two lines */}
        <p className="text-center mb-8 text-white text-md mt-6">
          Introducing Our Product: 360° Sales Solution <br />
          The Ultimate Sales Dashboard and Mobile App"
        </p>

        <div className="image-animation2">
        <img
          src={triangel1}
          alt="Triangle 1"
          className={`animated-image ${currentImage === triangel1 ? 'visible' : 'hidden'}`}
        />
        <img
          src={triangel2}
          alt="Triangle 2"
          className={`animated-image ${currentImage === triangel2 ? 'visible' : 'hidden'}`}
        />
      </div>
      
        <div className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
          <div className="bg-blue-950 rounded-lg p-8 text-center mx-auto boxshadow">
            {/* Title */}
            <h2 className="text-2xl md:text-5xl font-normal pt-10 mb-2 text-white">360° Sales Solution</h2>
            {/* Subtitle */}
            <p className="text-md md:text-lg text-gray-500 mb-10 mt-5">
                Revolutionary sales rep dashboard and mobile app <br/> designed to empower sales teams
            </p>
            {/* Image */}
            <img src={salesSolution} alt="Product" className="mx-auto mb-4" />
            {/* Read More Button */}
            <div className="relative btnmore">
              <a href="#"
                  className="inline-flex items-center py-2 px-6 mx-4 text-primary border border-1 border-primary rounded-full bg-buttonbg hover:bg-hover-buttonbg 
                             absolute bottom-0 right-0 -mr-3 transform translate-x-1/2 translate-y-1/2">
                Read More
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Section5;
