import React from 'react';

const Section2 = () => {

    const containerStyle = {
        background: "linear-gradient(to bottom, #5788BE 0%, #FCE5D7 100%)",
    };

    return (
        <section style={containerStyle} className='pt-16'>
            <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-4xl font-bold text-white">Dynamism</h2>
                <p className="mt-4 text-white text-md">Will define your market structure by</p>
            </div>

            <div className="flex justify-center items-center py-14">
                <div className="w-3/4 scetion2card xl:p-20 md:p-20">


                    <div className="flex flex-wrap xl:m-5 md:m-5">

                        <div className="sm:w-1/2 md:w-1/3 xl:p-2 md:p-2 pb-20">
                            <div>
                                <h3 className='mb-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7981 2.80005L32.1174 4.89993L33.9002 5.8283C34.5291 6.14559 35.1051 6.40383 36.143 6.79515L37.3619 7.24079C38.9662 7.8337 40.0959 8.02352 40.5628 8.08268L40.7138 8.10002L40.9577 8.11104L41.1623 8.12481L49 8.75133V32.7616C49 44.2621 32.2501 53.2001 28 53.2001C23.0714 53.2001 7.40864 44.0121 7.00784 33.1843L7 32.7616V8.71415L12.6187 8.32377C13.3531 8.26769 14.0379 8.21211 14.5817 8.16208L15.139 8.10497C15.5382 8.05096 16.8605 7.8381 18.9456 7.17607L19.5797 6.94097C21.1223 6.35226 22.3377 5.76633 23.0368 5.40688L23.697 5.05278L27.7981 2.80005ZM27.9174 7.03834L25.7051 8.23454L25.2614 8.47882C25.0829 8.57382 24.8728 8.68245 24.6277 8.8049L23.826 9.19361C22.8993 9.62942 21.8817 10.0625 20.7915 10.4714C20.507 10.5781 20.2202 10.6821 19.9313 10.7832C18.6808 11.1765 17.5165 11.4749 16.5016 11.6792L16.0759 11.7609C15.607 11.8466 15.2686 11.8949 15.0658 11.9176L14.9084 11.9323L10.5 12.2538V32.7966L10.5083 33.1682C10.6852 37.126 13.6746 40.8864 18.3456 44.4091C21.9236 47.1075 26.1919 49.3768 28 49.3768C29.7482 49.3768 34.027 47.0916 37.5965 44.4132C42.3062 40.8792 45.3137 37.1286 45.4916 33.1684L45.5 32.7966V12.2693L41.2712 11.9354L41.0508 11.9268L40.8684 11.9148L40.453 11.8724C40.301 11.8536 40.1354 11.8299 39.9568 11.8008C39.0163 11.6472 37.9436 11.3815 36.7543 10.9769L35.5123 10.5425L34.6389 10.2173C33.7826 9.88641 32.9897 9.54351 32.2664 9.19953L31.5216 8.83277L30.9903 8.5541L30.8524 8.4785L30.5598 8.30968L30.2979 8.16788L27.9174 7.03834Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2808 13.4262C38.855 14.7268 41.0516 14.7268 41.0516 14.7268L42.7008 14.857V32.7966C42.7008 39.4284 29.51 46.5768 28.0008 46.5768C26.3516 46.5768 13.3008 39.4284 13.3008 32.7966V14.857L15.0858 14.7268C15.0858 14.7268 17.1466 14.5966 20.8566 13.4262C24.5666 12.1284 27.0376 10.6976 27.0376 10.6976L28.0008 10.1768L29.0984 10.6976C29.0984 10.6976 31.435 12.1284 35.2808 13.4262ZM20.1689 29.417L27.0373 35.3978L37.3413 23.1772L34.8689 20.9666L26.6257 30.7176L22.2297 26.9474L20.1689 29.417Z" fill="#1A518C" />
                                    </svg>
                                </h3>
                                <p className="cardtitle mb-5">
                                    Security and privacy
                                </p>
                                <p className="cardsubtitle">
                                    Ensures that sensitive sales data is protected from unauthorized access, maintaining the confidentiality and integrity of customer information.
                                </p>
                            </div>
                        </div>

                        <div className="sm:w-1/2 md:w-1/3 xl:p-2 md:p-2 pb-20">
                            <div>
                                <h3 className='mb-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2008 16.8001C11.2008 17.212 11.1415 17.6101 11.031 17.9862L18.3949 22.2378C17.9141 23.0376 17.5304 23.9023 17.26 24.8157L9.37641 20.2642C8.70076 20.7284 7.8825 21.0001 7.00078 21.0001C4.68119 21.0001 2.80078 19.1197 2.80078 16.8001C2.80078 14.4805 4.68119 12.6001 7.00078 12.6001C9.32038 12.6001 11.2008 14.4805 11.2008 16.8001ZM26.6006 39.1134C27.0593 39.1707 27.5266 39.2001 28.0008 39.2001C28.4748 39.2001 28.942 39.1707 29.4006 39.1135V46.439C31.032 47.0155 32.2008 48.5713 32.2008 50.4001C32.2008 52.7197 30.3204 54.6001 28.0008 54.6001C25.6812 54.6001 23.8008 52.7197 23.8008 50.4001C23.8008 48.5715 24.9694 47.0158 26.6006 46.4392V39.1134ZM38.7416 24.8157C38.4712 23.9023 38.0875 23.0376 37.6067 22.2378L44.9706 17.9862C44.8601 17.6101 44.8008 17.212 44.8008 16.8001C44.8008 14.4805 46.6812 12.6001 49.0008 12.6001C51.3204 12.6001 53.2008 14.4805 53.2008 16.8001C53.2008 19.1197 51.3204 21.0001 49.0008 21.0001C48.1191 21.0001 47.3008 20.7284 46.6251 20.2642L38.7416 24.8157Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.3996 13.8437C32.205 13.2069 34.2992 10.698 34.2992 7.6999C34.2992 4.22051 31.4786 1.3999 27.9992 1.3999C24.5198 1.3999 21.6992 4.22051 21.6992 7.6999C21.6992 10.6983 23.7938 13.2074 26.5996 13.8439V19.716C22.6268 20.3825 19.5996 23.8377 19.5996 27.9999C19.5996 29.814 20.1747 31.4937 21.1524 32.8668L15.2534 38.7658C14.2823 38.1539 13.1323 37.7999 11.8996 37.7999C8.42022 37.7999 5.59961 40.6205 5.59961 44.0999C5.59961 47.5793 8.42022 50.3999 11.8996 50.3999C15.379 50.3999 18.1996 47.5793 18.1996 44.0999C18.1996 42.867 17.8455 41.7169 17.2334 40.7457L23.1323 34.8468C24.5055 35.8247 26.1854 36.3999 27.9996 36.3999C29.8138 36.3999 31.4936 35.8248 32.8668 34.8469L38.7658 40.7459C38.1539 41.7171 37.7998 42.8671 37.7998 44.0999C37.7998 47.5793 40.6204 50.3999 44.0998 50.3999C47.5792 50.3999 50.3998 47.5793 50.3998 44.0999C50.3998 40.6205 47.5792 37.7999 44.0998 37.7999C42.867 37.7999 41.7169 38.154 40.7457 38.766L34.8467 32.867C35.8245 31.4939 36.3996 29.814 36.3996 27.9999C36.3996 23.8377 33.3724 20.3825 29.3996 19.716V13.8437Z" fill="#1A518C" />
                                    </svg>
                                </h3>
                                <p className="cardtitle mb-5">
                                    Built-in AI features
                                </p>
                                <p className="cardsubtitle">
                                    Integrates artificial intelligence capabilities, such as predictive analytics and personalized recommendations, to assist sales reps in making data-driven decisions.
                                </p>
                            </div>
                        </div>

                        <div className="sm:w-1/2 md:w-1/3 xl:p-2 md:p-2 pb-20">
                            <div>
                                <h3 className='mb-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8986 27.1195C13.5577 25.3452 10.501 21.0812 10.501 16.1C10.501 9.5252 15.8262 4.19995 22.401 4.19995C28.9757 4.19995 34.301 9.5252 34.301 16.1C34.301 21.0812 31.2442 25.3453 26.9032 27.1196C31.0811 28.1016 34.7436 30.4204 37.4147 33.5999H30.6783L30.4469 33.6063C28.3025 33.7247 26.6006 35.4793 26.6006 37.6264V46.1999H2.80078C2.80078 36.9244 9.24386 29.1538 17.8986 27.1195ZM28.6877 18.9737C27.9626 21.8111 25.3717 23.8 22.4005 23.8C19.4293 23.8 16.8384 21.8111 16.1133 18.9737L19.2561 18.2C19.615 19.6225 20.912 20.6213 22.4005 20.6213C23.889 20.6213 25.186 19.6225 25.5449 18.2L28.6877 18.9737Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4004 37.9144C29.4004 37.0779 30.0784 36.3999 30.9148 36.3999H50.2859C51.1224 36.3999 51.8004 37.0779 51.8004 37.9144V49.2371C51.8004 50.0735 51.1223 50.7515 50.2859 50.7515H45.1182C44.4363 50.7581 42.6219 52.0591 41.5109 52.9071C40.9844 53.309 40.24 53.2803 39.7337 52.8532C38.7282 52.0052 37.0812 50.7579 36.0848 50.7515H30.9148C30.0784 50.7515 29.4004 50.0735 29.4004 49.2371V37.9144ZM35.3231 45.0108C36.1558 45.0108 36.8308 44.3683 36.8308 43.5757C36.8308 42.783 36.1558 42.1405 35.3231 42.1405C34.4904 42.1405 33.8154 42.783 33.8154 43.5757C33.8154 44.3683 34.4904 45.0108 35.3231 45.0108ZM40.6005 45.0108C41.4331 45.0108 42.1082 44.3683 42.1082 43.5757C42.1082 42.783 41.4331 42.1405 40.6005 42.1405C39.7678 42.1405 39.0928 42.783 39.0928 43.5757C39.0928 44.3683 39.7678 45.0108 40.6005 45.0108ZM47.3845 43.5757C47.3845 44.3683 46.7095 45.0108 45.8768 45.0108C45.0442 45.0108 44.3691 44.3683 44.3691 43.5757C44.3691 42.783 45.0442 42.1405 45.8768 42.1405C46.7095 42.1405 47.3845 42.783 47.3845 43.5757Z" fill="#1A518C" />
                                    </svg>
                                </h3>
                                <p className="cardtitle mb-5">
                                    Collaborate with your team
                                </p>
                                <p className="cardsubtitle">
                                    Enables seamless communication and collaboration among sales team members, facilitating information sharing and teamwork.
                                </p>
                            </div>
                        </div>

                        <div className="sm:w-1/2 md:w-1/3 xl:p-2 md:p-2 pb-20">
                            <div>
                                <h3 className='mb-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M50.3996 18.1602L38.4296 5.59937L8.39961 5.6C6.85321 5.6 5.59961 6.85361 5.59961 8.4V47.6C5.59961 49.1464 6.85321 50.4 8.39961 50.4H47.5996C49.146 50.4 50.3996 49.1464 50.3996 47.6V18.1602ZM9.7998 9.79858H36.6294L46.1998 19.8408V46.1986H9.7998V9.79858Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.3996 25.2H12.5996V43.4H43.3996V25.2ZM39.1992 29.4H32.1992V36.4H39.1992V29.4Z" fill="#1A518C" />
                                        <rect x="12.5996" y="14" width="16.8" height="5.6" fill="#1A518C" />
                                    </svg>
                                </h3>
                                <p className="cardtitle mb-5">
                                    Real time sync
                                </p>
                                <p className="cardsubtitle">
                                    Allows sales reps to access and update data in real time, ensuring that they always have the latest information at their fingertips.
                                </p>
                            </div>
                        </div>

                        <div className="sm:w-1/2 md:w-1/3 xl:p-2 md:p-2 pb-20">
                            <div>
                                <h3 className='mb-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7996 5.6001H9.79961H6.99961C6.22641 5.6001 5.59961 6.2269 5.59961 7.0001V7.0001V9.8001V16.8001C5.59961 17.5733 6.22641 18.2001 6.99961 18.2001H8.39961C9.17281 18.2001 9.79961 17.5733 9.79961 16.8001V9.8001H16.7996C17.5728 9.8001 18.1996 9.1733 18.1996 8.4001V7.0001C18.1996 6.2269 17.5728 5.6001 16.7996 5.6001Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2004 5.6001H46.2004H49.0004C49.7736 5.6001 50.4004 6.2269 50.4004 7.0001V7.0001V9.8001V16.8001C50.4004 17.5733 49.7736 18.2001 49.0004 18.2001H47.6004C46.8272 18.2001 46.2004 17.5733 46.2004 16.8001V9.8001H39.2004C38.4272 9.8001 37.8004 9.1733 37.8004 8.4001V7.0001C37.8004 6.2269 38.4272 5.6001 39.2004 5.6001Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7996 51.8H9.79961H6.99961C6.22641 51.8 5.59961 51.1732 5.59961 50.4V50.4V47.6V40.6C5.59961 39.8268 6.22641 39.2 6.99961 39.2H8.39961C9.17281 39.2 9.79961 39.8268 9.79961 40.6V47.6H16.7996C17.5728 47.6 18.1996 48.2269 18.1996 49V50.4C18.1996 51.1732 17.5728 51.8 16.7996 51.8Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2004 51.8H46.2004H49.0004C49.7736 51.8 50.4004 51.1732 50.4004 50.4V50.4V47.6V40.6C50.4004 39.8268 49.7736 39.2 49.0004 39.2H47.6004C46.8272 39.2 46.2004 39.8268 46.2004 40.6V47.6H39.2004C38.4272 47.6 37.8004 48.2269 37.8004 49V50.4C37.8004 51.1732 38.4272 51.8 39.2004 51.8Z" fill="#1A518C" />
                                        <path d="M39.2476 29.7903C38.7356 31.5962 38.2003 33.4691 37.8978 35.1859C37.4556 37.7722 37.6185 39.9795 37.8047 41.2281L24.7716 46.2001V39.9349C24.7716 39.6005 24.6319 39.2884 24.3992 39.0654C24.1665 38.8424 23.8406 38.7087 23.4915 38.7087H18.7903C18.255 38.7087 17.8128 38.3073 17.8128 37.8168V32.8225C17.8128 32.1759 17.3008 31.6408 16.6259 31.5962L15.1131 31.4848C13.8331 31.3956 13.3909 31.3064 13.2978 31.2841C12.5996 30.972 12.5996 30.5483 12.5996 30.4146C12.5996 30.37 12.7392 29.3667 15.951 25.7547L16.0673 25.6209C16.4164 25.2196 16.4164 24.8183 16.4164 24.0602V23.7035C16.4397 23.6143 16.4397 23.5474 16.4397 23.4582C16.463 17.8619 22.3744 12.667 28.8211 12.6001H28.9608C35.2446 12.6001 40.5975 17.4829 40.5975 23.2353C40.644 24.9521 39.9225 27.4046 39.2476 29.7903Z" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7958 22.3173V26.5999H19.4336V19.6223H20.6488L23.4124 23.8951V19.6223H24.7746V26.5999H23.5594L20.7958 22.3173ZM30.929 25.3847V26.5999H26.4014V19.6223H27.7636V25.3847H30.929ZM33.3983 26.5999V23.9735H34.7409C35.1003 23.9735 35.422 23.9163 35.7062 23.802C35.9904 23.6877 36.2322 23.5309 36.4314 23.3316C36.6307 23.1323 36.7826 22.9004 36.8871 22.6358C36.9917 22.3712 37.0439 22.0919 37.0439 21.7979C37.0439 21.5039 36.9917 21.2246 36.8871 20.96C36.7826 20.6954 36.6307 20.4635 36.4314 20.2642C36.2322 20.0649 35.9904 19.9081 35.7062 19.7938C35.422 19.6795 35.1003 19.6223 34.7409 19.6223H32.0361V26.5999H33.3983ZM33.3984 22.7485H34.6724C34.986 22.7485 35.2327 22.662 35.4123 22.4888C35.592 22.3157 35.6818 22.0854 35.6818 21.7979C35.6818 21.5105 35.592 21.2785 35.4123 21.1021C35.2327 20.9257 34.986 20.8375 34.6724 20.8375H33.3984V22.7485Z" fill="white" />
                                    </svg>
                                </h3>
                                <p className="cardtitle mb-5">
                                    Mobile App Compatibility
                                </p>
                                <p className="cardsubtitle">
                                    Provides a mobile app version of the dashboard, enabling sales reps to access and manage sales-related data on their smartphones or tablets.
                                </p>
                            </div>
                        </div>

                        <div className="sm:w-1/2 md:w-1/3 xl:p-2 md:p-2 pb-20">
                            <div>
                                <h3 className='mb-5'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                                        <rect x="51.8008" y="4.19995" width="8.4" height="47.6" rx="1.4" transform="rotate(90 51.8008 4.19995)" fill="#1A518C" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.1992 16.7999C32.1992 16.0267 31.5724 15.3999 30.7992 15.3999H5.59922C4.82602 15.3999 4.19922 16.0267 4.19922 16.7999V50.3999C4.19922 51.1731 4.82602 51.7999 5.59922 51.7999H30.7992C31.5724 51.7999 32.1992 51.1731 32.1992 50.3999V16.7999ZM26.5988 22.3999H9.79884V25.1999H26.5988V22.3999ZM9.79884 32.1999H26.5988V34.9999H9.79884V32.1999ZM26.5988 41.9999H9.79884V44.7999H26.5988V41.9999Z" fill="#1A518C" />
                                        <rect x="35" y="15.3999" width="16.8" height="36.4" rx="1.4" fill="#1A518C" />
                                    </svg>
                                </h3>
                                <p className="cardtitle mb-5">
                                    Easy Data organization
                                </p>
                                <p className="cardsubtitle">
                                    Provides tools to categorize, structure, and manage sales-related data in an organized and user-friendly manner.
                                </p>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </section>
    );
};

export default Section2;
