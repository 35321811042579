import React, { useState } from 'react';
import bannerImgSrc from '../../assets/frontend/images/animation/robot.svg';
import section1bImgSrc from '../../assets/frontend/images/animation/robot2.svg';

const Banner = () => {

    const [showDefault, setShowDefault] = useState(true);

    const handleMouseEnter = () => {
        setShowDefault(false);
    };

    const handleMouseLeave = () => {
        setShowDefault(true);
    };

    return (
        <div className="mt-20 spotlight-section relative" id='Home-section'>
            <div className="flex flex-col items-center justify-center">

                <div className="elipsbanner"></div>
                <div className="elipsbanner2"></div>

                <h2 className="section1header" style={{ zIndex: 2 }}>
                    Executive Solutions Marketing Research
                </h2>
                <p className="section1subtitle" style={{ zIndex: 2 }}>
                    Data Driven Decisions
                </p>

                <div className="flex flex-col items-center justify-center">
                    <div className="relative"
                         onMouseEnter={handleMouseEnter}
                         onMouseLeave={handleMouseLeave}>
                        <img
                            className="my-8 max-w-full h-auto"
                            src={showDefault ? bannerImgSrc : section1bImgSrc}
                            alt="Responsive Image"
                            style={{ zIndex: 1, transition: 'opacity 1s ease-in-out' }} />
                    </div>
                </div>

                <div className="star1"></div>
                <div className="star2"></div>
                <div className="star3"></div>
                <div className="star4"></div>
                <div className="star5"></div>
            </div>
        </div>
    );
};

export default Banner;
