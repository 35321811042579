import React from 'react';

const Section4 = () => {
  const containerStyle = {
    background: "linear-gradient(to bottom, #FCE5D7 0%, #99B3D6 100%)",
  };

  return (
      <section style={containerStyle} className="flex flex-col md:flex-row justify-between p-14">

      {/* Left Column */}
        <div className="md:w-1/3 text-center md:text-left mb-4 md:mb-0">
        <p className='section4subtitle'>Discover More</p>
        <h2 className="text-4xl font-bold section4title mt-12" style={{ color: '#000000' }}>
            Analyze your sales and <br />
            marketing leads
        </h2>
        <p className="text-md font-normal mt-12">
          This feature provides tools and insights to help businesses understand the characteristics, behaviors, and potential of their leads, ultimately aiding in making informed decisions to optimize the sales and marketing strategies.
        </p>
        {/* Add more subtitles if needed */}
      </div>

      {/* Right Column */}
        <div className="md:w-1/3 flex flex-col gap-4 mt-8 md:mt-0">
        {/* Row 1 */}
        <div className="flex items-center gap-8 mb-6">
          <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 81 80" fill="none">
          <rect x="0.783203" width="79.7838" height="79.7838" rx="39.8919" fill="#F4F5F7"/>
          <rect x="20.582" y="45.6001" width="32.4" height="2.7" rx="1.35" fill="url(#paint0_radial_268_7169)"/>
          <rect x="24.1836" y="33.8999" width="5.4" height="9" rx="1.4" fill="#1D2130"/>
          <rect x="34.082" y="26.7" width="5.4" height="16.2" rx="1.4" fill="#1D2130"/>
          <rect x="43.9844" y="17.7" width="5.4" height="25.2" rx="1.4" fill="#1D2130"/>
          <defs>
            <radialGradient id="paint0_radial_268_7169" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(4.54322 38.8501) rotate(9.76422) scale(77.0384 98.9563)">
              <stop offset="0.175497" stop-color="#D798E1"/>
              <stop offset="0.275566" stop-color="#9B9FFF"/>
              <stop offset="0.49892" stop-color="#AED3FF"/>
              <stop offset="0.565292" stop-color="#C9D4EF"/>
              <stop offset="0.656871" stop-color="#CACFFA"/>
            </radialGradient>
          </defs>
        </svg>
          </div>
          <div>
            <h3 className="text-md font-bold">Lead Insights and Prioritization</h3>
            <p className="text-sm text-gray-600 pt-2">Categorize and tailor approaches for different lead types</p>
          </div>
        </div>

        {/* Row 2 */}
        <div className="flex items-center gap-8 mb-6">
          <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 81 80" fill="none">
          <rect x="0.783203" y="0.108032" width="79.7838" height="79.7838" rx="39.8919" fill="#F4F5F7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.2878 35.5419C31.4972 34.4013 29.5322 31.6602 29.5322 28.458C29.5322 24.2314 32.9556 20.808 37.1822 20.808C41.4089 20.808 44.8322 24.2314 44.8322 28.458C44.8322 31.6602 42.8672 34.4014 40.0765 35.542C42.7623 36.1734 45.1167 37.664 46.8338 39.7079H42.5032L42.3545 39.712C40.9759 39.7881 39.8818 40.9161 39.8818 42.2963V47.808H24.582C24.582 41.8451 28.724 36.8497 34.2878 35.5419ZM41.2242 30.3054C40.7581 32.1294 39.0925 33.408 37.1824 33.408C35.2723 33.408 33.6068 32.1294 33.1406 30.3054L35.161 29.808C35.3917 30.7225 36.2255 31.3645 37.1824 31.3645C38.1393 31.3645 38.9731 30.7225 39.2038 29.808L41.2242 30.3054Z" fill="#1D2130"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M41.6836 43.0224C41.6836 42.186 42.3616 41.5079 43.198 41.5079H54.5691C55.4056 41.5079 56.0836 42.186 56.0836 43.0224V49.2195C56.0836 50.0559 55.4056 50.734 54.5691 50.734H43.198C42.3616 50.734 41.6836 50.0559 41.6836 49.2195V43.0224ZM45.9756 50.734H51.791C51.4175 50.734 50.5041 51.3506 49.7999 51.8709C49.2672 52.2645 48.5242 52.235 48.0059 51.8225C47.3658 51.3132 46.5186 50.734 45.9756 50.734ZM45.4917 47.0436C46.027 47.0436 46.4609 46.6305 46.4609 46.121C46.4609 45.6114 46.027 45.1984 45.4917 45.1984C44.9564 45.1984 44.5225 45.6114 44.5225 46.121C44.5225 46.6305 44.9564 47.0436 45.4917 47.0436ZM48.8843 47.0436C49.4196 47.0436 49.8535 46.6305 49.8535 46.121C49.8535 45.6114 49.4196 45.1984 48.8843 45.1984C48.349 45.1984 47.915 45.6114 47.915 46.121C47.915 46.6305 48.349 47.0436 48.8843 47.0436ZM53.2451 46.121C53.2451 46.6305 52.8112 47.0436 52.2759 47.0436C51.7406 47.0436 51.3066 46.6305 51.3066 46.121C51.3066 45.6114 51.7406 45.1984 52.2759 45.1984C52.8112 45.1984 53.2451 45.6114 53.2451 46.121Z" fill="url(#paint0_radial_268_7149)"/>
          <defs>
            <radialGradient id="paint0_radial_268_7149" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(34.5552 14.9037) rotate(56.7643) scale(61.5658 216.908)">
              <stop offset="0.175497" stop-color="#D798E1"/>
              <stop offset="0.275566" stop-color="#9B9FFF"/>
              <stop offset="0.49892" stop-color="#AED3FF"/>
              <stop offset="0.565292" stop-color="#C9D4EF"/>
              <stop offset="0.656871" stop-color="#CACFFA"/>
            </radialGradient>
          </defs>
        </svg>
          </div>
          <div>
            <h3 className="text-md font-bold">Effective Marketing Strategies</h3>
            <p className="text-sm text-gray-600 pt-2">Determine the best-performing marketing channels.</p>
          </div>
        </div>

        {/* Row 3 */}
        <div className="flex items-center gap-8">
          <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 81 80" fill="none">
          <rect x="0.783203" y="0.216187" width="79.7838" height="79.7838" rx="39.8919" fill="#F4F5F7"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M46.1972 23.9921C46.1974 23.9922 46.1977 23.9924 46.1979 23.9925L44.2254 25.9646C44.2253 25.9645 44.2251 25.9644 44.225 25.9643L43.0826 27.1067V25.413C43.0824 25.4129 43.0822 25.4128 43.082 25.4127V24.516L38.582 24.516L36.7822 24.516L34.982 24.516C28.5203 24.516 23.282 29.7543 23.282 36.216C23.282 39.1904 24.3919 41.9056 26.2202 43.97L24.3081 45.882C21.9926 43.3266 20.582 39.9361 20.582 36.216C20.582 28.2631 27.0291 21.816 34.982 21.816H36.7822H38.582H43.0826H44.8822H48.3733L46.1972 23.9921Z" fill="url(#paint0_radial_268_7132)"/>
          <path d="M42.7883 39.3222L39.6066 38.0838C39.6066 38.0838 38.9996 37.849 38.7642 37.6179C38.613 37.4689 38.6231 37.119 38.757 36.6704C38.8916 36.2218 40.5224 34.3995 40.5224 32.7442C40.5224 30.1882 38.8391 28.1161 36.764 28.1161C34.6912 28.1161 33.0092 30.189 33.0092 32.7442C33.0092 34.3304 34.4629 36.2204 34.6422 36.5624C34.8215 36.9073 34.7776 37.43 34.6372 37.5884C34.4248 37.8267 33.6464 38.065 33.6464 38.065L30.778 39.3409C30.1076 39.5814 29.582 40.2121 29.582 40.9249V41.2626C29.582 42.0402 30.2135 42.5161 30.9904 42.5161H42.5723C43.3484 42.5161 43.982 42.0394 43.982 41.2626V40.9249C43.9835 40.2121 43.4579 39.5619 42.7883 39.3222Z" fill="#1D2130"/>
          <path d="M38.5834 47.9162C45.0451 47.9162 50.2834 42.6779 50.2834 36.2162C50.2834 33.2418 49.1735 30.5266 47.3452 28.4622L49.2573 26.5502C51.5728 29.1056 52.9834 32.4961 52.9834 36.2162C52.9834 44.1691 46.5363 50.6162 38.5834 50.6162L25.1914 50.6165L30.4834 45.3245V47.9162H38.5834Z" fill="url(#paint1_radial_268_7132)"/>
          <defs>
            <radialGradient id="paint0_radial_268_7132" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.82467 -38.3489) rotate(60.7856) scale(133.427 436.828)">
              <stop offset="0.175497" stop-color="#D798E1"/>
              <stop offset="0.275566" stop-color="#9B9FFF"/>
              <stop offset="0.49892" stop-color="#AED3FF"/>
              <stop offset="0.565292" stop-color="#C9D4EF"/>
              <stop offset="0.656871" stop-color="#CACFFA"/>
            </radialGradient>
            <radialGradient id="paint1_radial_268_7132" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(11.4337 -33.6155) rotate(60.7853) scale(133.429 436.838)">
              <stop offset="0.175497" stop-color="#D798E1"/>
              <stop offset="0.275566" stop-color="#9B9FFF"/>
              <stop offset="0.49892" stop-color="#AED3FF"/>
              <stop offset="0.565292" stop-color="#C9D4EF"/>
              <stop offset="0.656871" stop-color="#CACFFA"/>
            </radialGradient>
          </defs>
        </svg>
          </div>
          <div>      
            <h3 className="text-md font-bold">Data-Driven Decision-Making</h3>
            <p className="text-sm text-gray-600 pt-2"> Visualize key metrics for informed decision-making..</p>
          </div>
        </div>
       
      </div>

    </section>
  );
};

export default Section4;
