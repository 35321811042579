import React, { useState, useEffect } from 'react';
import Default from '../../assets/frontend/images/animation/Octagon1.svg';
import Variant2 from '../../assets/frontend/images/animation/Octagon2.svg';
import cardmainbg from "../../assets/frontend/images/cardmainbg.svg";
import cardbg from "../../assets/frontend/images/cardbg.svg";

const Section3 = () => {
    const [currentImage, setCurrentImage] = useState(Default);
    const [imageOpacity, setImageOpacity] = useState(1);

    useEffect(() => {
        // Change the image source and animate opacity smoothly
        const interval = setInterval(() => {
            setImageOpacity(0.1); // Set lower opacity during transition
            setTimeout(() => {
                setCurrentImage(currentImage === Default ? Variant2 : Default);
                setImageOpacity(0.1); // Fade in
            }, 15); // Wait for 500ms before changing image and fading in
        }, 1700); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [currentImage]);

    return (
        <div className='relative w-full'>

            <div className="relative">
                <div className="flex flex-col items-center justify-center mb-16 mt-32">
                    <h1 className="section2headtxt">
                        How we work?
                    </h1>
                    <p className="section2subtxt" style={{ zIndex: 2 }}>
                        Your Data Need To Join The New Paradigm
                    </p>
                </div>                
            </div>         
           
            <div className="w-full z-0 hoor">
            <img
                src={currentImage}
                        alt="Animated Octagon"
                        className="w-full h-full opacity-100 transition-opacity duration-300"
                        style={{ opacity: imageOpacity }}
                    />
            </div>

          <div className="flex justify-center">
          <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-12">

              {/* First Column */}
                  <div className="flex flex-col relative card1" style={{ backgroundImage: `url(${cardmainbg})`, backgroundSize: 'cover', backgroundPosition: 'center', maxHeight: '400px', maxWidth: '384px', opacity: 0.8 }}>
                    <div className="flex flex-col relative" style={{ backgroundImage: `url(${cardbg})`, backgroundRepeat :'no-repeat', opacity: 0.8 }}>
                     
                    <div className='p-12'>
                        <h3 className="text-xl font-semibold mb-2 text-white text-center pt-4">SET</h3>
                        <p className="text-sm text-white leading-7 pt-6 pb-12">
                            This innovative approach to data analysis through ESMR technologies ensures a heightened level of precision, 
                            scalability, and adaptability, thereby empowering your business to stay at the forefront of data-driven
                            innovation and maintain a competitive edge in today's dynamic and rapidly evolving digital ecosystem.   
                        </p>
                    </div>                 

                </div>
               </div>

              {/* Second Column */}
              <div className="flex flex-col relative card1" style={{ backgroundImage: `url(${cardmainbg})`, backgroundSize: 'cover', backgroundPosition: 'center', maxHeight: '400px', maxWidth: '384px', opacity: 0.8 }}>
                <div className="flex flex-col relative" style={{ backgroundImage: `url(${cardbg})`, backgroundRepeat :'no-repeat', opacity: 0.8 }}>
                <div className='p-10'>

                <h3 className="text-xl font-semibold mb-2 text-white text-center pt-4">ANALYSE</h3>
                    <p className="text-sm text-white  leading-7 pt-6 pb-20">
                    This innovative approach to data analysis through ESMR technologies ensures a heightened level of precision, scalability, and adaptability, thereby empowering your business to stay at the forefront of data-driven innovation and maintain a competitive edge in today's dynamic and rapidly evolving digital ecosystem.
                    </p>

                </div>
                </div>
              </div>

              {/* Third Column */}
              <div className="flex flex-col relative card1" style={{ backgroundImage: `url(${cardmainbg})`, backgroundSize: 'cover', backgroundPosition: 'center', maxHeight: '400px', maxWidth: '384px', opacity: 0.8}}>
                <div className="flex flex-col relative" style={{ backgroundImage: `url(${cardbg})`, backgroundRepeat :'no-repeat', opacity: 0.8 }}>
                <div className='p-12'>   
                <h3 className="text-xl font-semibold mb-2 text-white text-center pt-4">TAKE</h3>
                    <p className="text-sm text-white  leading-7 pt-6 pb-16">
                    The steadfast dedication to making the right and trusted decisions not only fortifies your organizational resilience but also fosters enduring relationships with partners, clients, and stakeholders, establishing a solid foundation for sustained success in today's dynamic business landscape.                  </p>
                </div>
                </div>
              </div>

          </div>
         </div>

         <div className='s3estar1'></div>
         <div className='s3estar2'></div>
         <div className='s3estar3'></div>

        </div>
    );
};

export default Section3;
