/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import businessanalysis from '../../assets/frontend/images/businessanalysis.svg';
import onlineanalysis from '../../assets/frontend/images/onlineanalysis.svg';
import secureshield from '../../assets/frontend/images/secureshield.svg';
import cardmainbg from "../../assets/frontend/images/frame.svg";

const Benefits = () => {

  return (
      <div className='relative mt-6'>

        <div className="flex flex-col items-center justify-center mb-16 mt-32">

          <h1 className="section2headtxt">
            Benefits
          </h1>

          {/* Text on two lines */}
          <p className="section2subtxt" style={{ zIndex: 2 }}>
            Simplify your big Data
          </p>

        </div>


          <div className="flex justify-center p-8 relative">
              <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">

                   <div className="elipsbenefits"></div>
                   <div className="elipsbenefits2"></div>
                  {/* First Column */}
                      <div className="flex flex-col relative p-10" style={{ backgroundImage: `url(${cardmainbg})`, backgroundSize: 'cover', backgroundPosition: 'center', maxHeight: '460px', maxWidth: '387px', opacity: 0.8 }}>
                      <img src={businessanalysis} alt="Image 1" className="w-80 h-auto mb-4 p-1" />
                      <h3 className="text-xl font-semibold mb-2 text-white text-left">SIMPLE</h3>
                      <p className="text-sm text-gray-100">
                          a platform that looks professional and easy for anyone to navigate
                      </p>
                  </div>

                  {/* Second Column */}
                  <div className="flex flex-col relative p-10" style={{ backgroundImage: `url(${cardmainbg})`, backgroundSize: 'cover', backgroundPosition: 'center', maxHeight: '460px', maxWidth: '387px', opacity: 0.8 }}>
                      <img src={onlineanalysis} alt="Image 2" className="w-80 h-auto mb-4 p-1" />
                      <h3 className="text-xl font-semibold mb-2 text-white text-left">SMART</h3>
                      <p className="text-sm text-gray-100">
                          An instant market evolution tool that the decision-making process smarter
                      </p>
                  </div>

                  {/* Third Column */}
                  <div className="flex flex-col relative p-10" style={{ backgroundImage: `url(${cardmainbg})`, backgroundSize: 'cover', backgroundPosition: 'center', maxHeight: '460px', maxWidth: '387px', opacity: 0.8 }}>
                      <img src={secureshield} alt="Image 3" className="w-80 h-auto mb-4 p-1" />
                      <h3 className="text-xl font-semibold mb-2 text-white text-left">SECURE</h3>
                      <p className="text-sm text-gray-100">
                          ensuring that data, in any form, is protected from any corruption
                      </p>
                  </div>

              </div>
          </div>

          <div className="s5estar1"></div>
          <div className="s5estar2"></div>
          <div className="s5estar3"></div>
          <div className="s5ellipse"></div>

      </div>
  );
};

export default Benefits;