/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import slideImage1 from '../../assets/frontend/images/slides/slide1.svg';
import slideImage2 from '../../assets/frontend/images/slides/slide2.svg';
import mission from '../../assets/frontend/images/animation/mission.svg';
import vision from '../../assets/frontend/images/animation/vision.svg';
import hexagons1 from '../../assets/frontend/images/animation/hexagons1.svg';
import hexagons2 from '../../assets/frontend/images/animation/hexagons2.svg';

const Section3 = () => {
    const [activeDiv, setActiveDiv] = useState('alaa1');   

    useEffect(() => {
        // Switch divs after a certain time
        const timer = setTimeout(() => {
            setActiveDiv(activeDiv === 'alaa1' ? 'alaa2' : 'alaa1');
        }, 5000); // Switch after 5 seconds

        return () => clearTimeout(timer);
    }, [activeDiv]);

  
    const [currentImage, setCurrentImage] = useState(hexagons1);
    const [imageOpacity, setImageOpacity] = useState(0);

    {/*
    useEffect(() => {
        const interval = setInterval(() => {
            // Fade out
            setImageOpacity(0);
            
            setTimeout(() => {
                // Change the image
                setCurrentImage(currentImage === hexagons1 ? hexagons2 : hexagons1);
                
                // Fade in
                setImageOpacity(1);
            }, 5); // Wait for 500ms before fading in
        }, 3000); // Change image every 8 seconds
        
        return () => clearInterval(interval);
    }, [currentImage]);
  
*/}

const handleMouseEnter = () => {
    setImageOpacity(0); // Start fading out
    setTimeout(() => {
        setCurrentImage(currentImage === hexagons2 ? hexagons2 : hexagons2); // Change the image
        setImageOpacity(1); // Start fading in
    }, 0); // Slight delay before image change and fading in
};
    return (
        <div className='alaa relative'>
            <div className="flex flex-col items-center justify-center mt-24">
                <h1 className="text-4xl font-light mb-4 text-white mt-24">
                    Our Mission and Vision
                </h1>
                <p className="text-center mb-8 sectiontext">
                    Introducing Our Mission and Vision
                </p>

                <div className="w-full md:w-1/2 py-8 mt-14">
                    {activeDiv === 'alaa1' && (
                        <div className="bg-blue-950 rounded-lg overflow-hidden boxshadow alaa1 p-20">
                            <h1 className="text-center text-3xl font-semibold mb-6 text-white">Our Mission</h1>
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                <div className="px-4 md:px-0 text-center">
                                    {/* Left column text */}
                                    <p className='text-gray-400 text-left mr-10'>
                                        ESMR's mission is to empower clients by transforming data into actionable insights through limitless visual analytics.
                                        By providing easy-to-use tools for building dashboards, conducting ad hoc analyses,
                                        and sharing work with others, ESMR aims to make a significant impact on businesses of all sizes, 
                                        from global enterprises to early-stage startups and small businesses.
                                    </p>
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                    {/* Right column image */}
                                    <img src={slideImage1} alt="Dashboard" />
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-4 -mb-10">
                                {/* Centered image */}
                                <img src={mission} alt="Slide 1" />
                            </div>
                        </div>
                    )}

                    {activeDiv === 'alaa2' && (
                        <div className="bg-blue-950 rounded-lg overflow-hidden boxshadow alaa2 p-20">
                        <h1 className="text-center text-3xl font-semibold mb-6 text-white">Our  Vision</h1>
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                <div className="px-4 md:px-0 text-center">
                                    {/* Left column text */}
                                    <p className='text-gray-400 text-left mr-10'>
                                    SMR envisions a world where data-driven decision-making is accessible to all companies. 
                                    By offering comprehensive 360 solutions, ESMR aims to enable organizations to gain a clear and deep understanding of their data,
                                    thereby facilitating informed and strategic choices that drive growth, efficiency, and success. 
                                    </p>
                                </div>
                                <div className="flex items-center justify-center mt-4 -mb-16">
                                    {/* Right column image */}
                                    <img src={slideImage2} alt="Dashboard" />
                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-36 -mb-10">
                                {/* Centered image */}
                                <img src={vision} alt="Slide 2" />
                            </div>
                        </div>
                    )}
                </div>     
            </div>

            
            <div className="image-container-Section3 -mr-52" style={{ opacity: imageOpacity, position: 'absolute', bottom: '-175px', right: '0', display: 'flex', justifyContent: 'flex-end' }}>
            <img
                src={currentImage}
                alt="Hexagons"
                onMouseEnter={handleMouseEnter}
                style={{
                    opacity: 0.1, // Adjust this value to control the opacity
                    transition: 'opacity 0.3s ease-in-out', 
                    width: '60%', height: 'auto' 
                }}
            />
        </div>
        
        </div>
    );
};

export default Section3;
