import User from './components/layouts/frontend-layout/index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Desktop from './components/pages/Desktop';
import Products from "./components/pages/products";

const App = () => {
  return (
    <Router>
      <div>
        <Routes>

          <Route path='/' element={<User />} >
              <Route index element={<Products />} />
              <Route path="home" element={<Home />} />
              <Route path="desktop" element={<Desktop />} />
          </Route>          

        </Routes>

        <ToastContainer /> {/* Add the ToastContainer component at the root of your app */}

      </div>
    </Router>
  );
};

export default App;

