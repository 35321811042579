import React from 'react';

const Section4 = () => {
    return (
        <div className='relative mt-24' id='services-section'>

            <div className="flex flex-col items-center justify-center mb-16 xl:mt-40 lg:mt-40 md:mt-20">
                <h1 className="section2headtxt">
                    ESMR Technologies
                </h1>
                {/* Text on two lines */}
                <p className="section2subtxt" style={{ zIndex: 2 }}>
                    Using the right technologies to make the right decisions
                </p>
            </div>

            {/* Timeline */}
            <div className="timeline">

                <div className="container left">
                    <div className="background-layer1"></div>
                    <div className="background-layer2">
                        <div className="content">
                            <h2>Limitless Visual Analytics</h2>
                            <p>
                                ESMR technologies empower clients to delve into vast big data sets with ease,
                                offering limitless possibilities for visual analytics. With intuitive dashboards and the
                                ability to perform ad hoc analyses in just a few clicks, businesses gain valuable insights effortlessly.
                            </p>
                        </div>
                    </div>
                </div>


                <div className="container right">
                    <div className="background-rlayer1"></div>
                    <div className="background-rlayer2">
                        <div className="content">
                            <h2>AI-Powered Algorithm Development</h2>
                            <p>
                                At the core of ESMR technologies lies an AI engine.
                                This engine is equipped with advanced algorithms trained to harness big data,
                                enabling it to make intricate predictions and provide well-informed suggestions.
                                This AI-driven approach enhances decision-making and drives efficiency.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container left">
                    <div className="background-layer1"></div>
                    <div className="background-layer2">
                        <div className="content">
                            <h2>Multifaceted Capabilities</h2>
                            <p>
                                The AI engine within ESMR technologies boasts remarkable versatility.
                                From image recognition to text deciphering, customer segmentation,
                                and predicting customer responses to diverse initiatives like promotions,
                                it proves invaluable in handling voluminous data and optimizing strategies.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container right">
                    <div className="background-rlayer1"></div>
                    <div className="background-rlayer2">
                        <div className="content">
                            <h2>Visualizing Market Data</h2>
                            <p>
                                Our ESMR technologies serve as a robust tool for clients seeking to gain a deeper understanding of market data.
                                By visualizing complex data points and patterns, businesses are better equipped to comprehend market dynamics and make impactful decisions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container left">
                    <div className="background-layer1"></div>
                    <div className="background-layer2">
                        <div className="content">
                            <h2>Driving Business Impact</h2>
                            <p>
                                Leveraging ESMR technologies enables clients to not only analyze data but also translate insights
                                into tangible business impacts. By incorporating data-driven strategies, companies can seize opportunities,
                                mitigate risks, and drive overall growth and success.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container right">
                    <div className="background-rlayer1"></div>
                    <div className="background-rlayer2">
                        <div className="content">
                            <h2>Transformative Business Insights</h2>
                            <p>
                                Incorporating ESMR technologies empowers businesses to unlock transformative insights.
                                By harnessing the power of AI, visual analytics, and predictive capabilities, clients gain a competitive edge,
                                adapting swiftly to changing market conditions and making informed choices.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <div className="s4estar1"></div>
            <div className="s4estar2"></div>

        </div>
    );
};

export default Section4;
