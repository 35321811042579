import React, { useState } from 'react';
import logo from '../../../assets/frontend/images/logo.png';
import companyname from '../../../assets/frontend/images/esmr.png';
import { Link, animateScroll as scroll } from 'react-scroll';
import Popup from "../../popup/Popup";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
      <nav className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between flex-wrap p-3 bg-navbar">

     <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
        <Link
          to="Home-section"
          smooth={true}
          duration={500}
          className="block mt-4 lg:inline-block lg:mt-0 mx-4 text-primary"
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} // Flex container
        >
          <img src={logo} className="w-75 h-10 mr-2" alt="Logo" />
          <img src={companyname} className="w-75 h-6 mr-2 mt-4" alt="Company Name" />
        </Link>
      </div>
      

        <div className="block lg:hidden">
          <button onClick={() => setIsOpen(!isOpen)}
              className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400 text-white">
            <svg
                className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg
                className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
          
        <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}>   
            
          <div className="text-sm lg:flex-grow">
           
          </div>
            
          <div className="lg:mx-20">

            <Link
                to="services-section" // ID of the target section
                smooth={true} // Add smooth scrolling
                duration={500} // Scroll duration in milliseconds
                className="block mt-4 lg:inline-block lg:mt-0 mx-4 text-primary"
                style={{ cursor: 'pointer' }} >
                Services
            </Link>
             
            <Link
                to="Products-section" // ID of the target section
                smooth={true} // Add smooth scrolling
                duration={500} // Scroll duration in milliseconds
                className="block mt-4 lg:inline-block lg:mt-0 mx-4 text-primary"
                style={{ cursor: 'pointer' }} >
                Products
            </Link>           

            <Link
                to="Aboutus-section" // ID of the target section
                smooth={true} // Add smooth scrolling
                duration={500} // Scroll duration in milliseconds
                className="block mt-4 lg:inline-block lg:mt-0 mx-4 text-primary"
                style={{ cursor: 'pointer' }} >
                About us
            </Link>
            
             {/* <button className="inline-flex items-center py-2 px-6 mx-4 text-primary border border-1 border-primary rounded-full bg-buttonbg hover:bg-hover-buttonbg">
                  Apply for a Demo
              </button>*/}

            <button
                onClick={togglePopup}
                className="inline-flex items-center py-2 px-6 mx-4 text-primary border border-1 border-primary rounded-full bg-buttonbg hover:bg-hover-buttonbg">
              Apply for a Demo
            </button>

            <Link
                to="Contactus-section" // ID of the target section
                smooth={true} // Add smooth scrolling
                duration={500} // Scroll duration in milliseconds
                className="inline-flex items-center bg-amber-500 py-2 px-5 mx-4 text-primary bg-transparent border border-1 border-primary rounded-full hover:bg-buttonbg hover:text-primary hover:border-primary"
                style={{ cursor: 'pointer' }} >
                Contact us
            </Link>

          </div>
            
        </div>

        {/* Popup component */}
        {isOpen && <Popup onClose={togglePopup} />}

      </nav>
  );
}
export default Header;
