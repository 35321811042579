/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import leftImage from '../../assets/frontend/images/backstory.svg';

const Section6 = () => {

  return (
      <div className='relative mt-6'>

        <div className="flex flex-col items-center justify-center mb-16 mt-32">

          <h1 className="section2headtxt">
              Backstory
          </h1>

          {/* Text on two lines */}
          <p className="section2subtxt" style={{ zIndex: 2 }}>
              The Genesis of a Data-Driven Business Revolution
          </p>

        </div>


          <div className="flex justify-center p-8">
              <div className="max-w-screen-xl mx-auto flex flex-col-reverse md:flex-row">

                  {/* Left Column - Text */}
                  <div className="w-full md:w-1/2 p-4 md:order-1 mr-16">
                      <p className="text-lg text-white font-extralight opacity-70 mt-6">
                          Nestled within the bustling cityscape of Jeddah, a trio of visionary entrepreneurs emerged.
                          With an innate grasp of the retail industry and a fervent dedication to strategic decision-making,
                          they conceived a data-driven start-up that harnessed technology's potent capabilities to reshape the business landscape.
                          Recognizing the pivotal role of meticulous market research, their venture aimed to seamlessly meld raw data with innovation,
                          propelling enterprises toward resounding success. In this urban crucible,
                          a pioneering narrative was woven—a saga of insight,
                          foresight, and transformative potential that would redefine market dynamics and fuel the evolution of modern commerce.
                      </p>
                  </div>

                  {/* Right Column - Image */}
                  <div className="w-full md:w-96 mx-10 md:order-2">
                      <img src={leftImage} alt="Image Description" className="max-w-full h-auto" />
                  </div>

              </div>
          </div>

          <div className="s6estar1"></div>

      </div>
  );
};

export default Section6;