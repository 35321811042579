import "../../assets/css/desktop.css"
import "../../assets/css/mediaqueries.css"
import "../../assets/css/keyframes.css"
import Section1 from "../Desktop/section1";
import Section2 from "../Desktop/section2";
import Section3 from "../Desktop/section3";
import Section4 from '../Desktop/section4';
import Section5 from '../Desktop/section5';
import Footer from "../shared/Desktop/footer";
import Header from './../shared/Desktop/header';

const Desktop = () => {
  return (
     <div>
       <Header />
       <Section1 />
       <Section2 />
       <Section3 />
       <Section4 />
       <Section5 />
       <Footer />
    </div>
  );
};

export default Desktop;