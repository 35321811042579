import React from 'react';
import phone from '../../assets/frontend/images/icon/phone.svg'
import email from '../../assets/frontend/images/icon/email.svg'
import location from '../../assets/frontend/images/icon/location.svg'

const Section6 = () => {
  return (
    <div id='Contactus-section'>
      <div className="flex flex-col items-center justify-center my-16">
        <h1 className="text-5xl font-light mb-10 text-white mt-16">Contact Us</h1>
   
        <div className="bg-white rounded-xl p-2 mb-10">
        <div className="grid md:grid-cols-3 gap-4">
          {/* map side alaa */}
          <div className="col-span-3 md:col-span-1 bg-white">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59352.696765546025!2d39.11145236760928!3d21.603733304997945!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3dade64b5f9b9%3A0x5acb46f4a9be8cda!2sAsh%20Shati%2C%20Jeddah%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1691447341679!5m2!1sen!2seg"
              width="100%"
              height="100%"
              allowFullScreen=""
              className="rounded-tl-xl rounded-bl-xl"
              loading="lazy"
            >
            </iframe>
          </div>
      
          {/* contact us */}
          <div className="col-span-3 md:col-span-2 bg-white rounded-xl p-6 md:p-14">
            {/* First Name and Last Name */}
            <div className="mb-6 grid md:grid-cols-2 gap-4">
              <div>
                <label className="block text-blue-950 text-xs">First Name</label>
                <input type="text" className="border-none w-full py-2 inp" placeholder='First Name' />
              </div>
              <div>
                <label className="block text-blue-950 text-xs">Last Name</label>
                <input type="text" className="border-none w-full py-2 inp" placeholder='Last Name' />
              </div>
            </div>
      
            {/* Email and Phone Number */}
            <div className="mb-6 grid md:grid-cols-2 gap-4">
              <div>
                <label className="block text-blue-950 text-xs">Email</label>
                <input type="text" className="border-none w-full py-2 inp" placeholder='Email' />
              </div>
              <div>
                <label className="block text-blue-950 text-xs">Phone Number</label>
                <input type="text" className="border-none w-full py-2 inp" placeholder='Phone Number' />
              </div>
            </div>
      
            {/* Select Subject */}
            <div className="mb-6">
              <label className="block text-blue-950 text-sm">Select Subject?</label>
              <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-12 mt-3">
                <label className="flex items-center">
                  <input type="radio" name="subject" value="math" className="form-radio text-blue-500" />
                  <span className="ml-2 text-xs">Math</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" name="subject" value="science" className="form-radio text-blue-500" />
                  <span className="ml-2 text-xs">Science</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" name="subject" value="history" className="form-radio text-blue-500" />
                  <span className="ml-2 text-xs">History</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" name="subject" value="english" className="form-radio text-blue-500" />
                  <span className="ml-2 text-xs">English</span>
                </label>
              </div>
            </div>
      
            {/* Message */}
            <div className="mb-6">
              <label className="block text-gray-600">Message</label>
              <textarea className="border-none w-full py-2 inp" rows="4" placeholder='Write your message..'></textarea>
            </div>
      
            {/* Send Message Button */}
            <div className="text-right">
              <button className="bg-blue-950 px-8 text-white py-2 rounded">
                Send Message
              </button>
            </div>
      
            {/* Phone, Email, and Address */}
            <div className="flex flex-col md:flex-row mt-8 space-y-2 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/3">
                {/* Phone */}
                <div className="flex items-center">
                  <img src={phone} alt="Phone Icon" className="w-6 h-6 mr-2" />
                  <span className='text-base'>+9665 667 66201</span>
                </div>
              </div>
              <div className="w-full md:w-1/3">
                {/* Email */}
                <div className="flex items-center">
                  <img src={email} alt="Email Icon" className="w-6 h-6 mr-2" />
                  <span className='text-base'>info@esmr.com</span>
                </div>
              </div>
              <div className="w-full md:w-1/3">
                {/* Address */}
                <div className="flex items-center">
                  <img src={location} alt="Address Icon" className="w-6 h-6 mr-2" />
                  <span className='text-base'>Saudi Arabia, Jeddah Ash Shati Dist</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        </div>
    </div>
  );
};

export default Section6;






