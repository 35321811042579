import React, { useState } from 'react';
import logo from '../../../assets/frontend/images/logo.png';
import companyname from '../../../assets/frontend/images/esmr.png';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

import Popup from "../../popup/Popup";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setShowPopup(false);
  };

  const togglePopup = () => {
    setIsOpen(false);
    setShowPopup(!showPopup);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between flex-wrap p-3 bg-navbar" style={{ background: '#2B286E' }}>

      <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
        <ScrollLink
          to="Home-section"
          smooth={true}
          duration={500}
          className="block mt-4 lg:inline-block lg:mt-0 mx-4 text-primary"
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} // Flex container
        >
          <img src={logo} className="w-75 h-10 mr-1 ml-8" alt="Logo" />
          <img src={companyname} className="w-75 h-6 mr-2 mt-2" alt="Company Name" />
        </ScrollLink>
      </div>

      <div className="block lg:hidden">
        <button onClick={toggleMenu}
          className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400 text-white">
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>

      <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}>
        <div className="text-sm lg:flex-grow">

        </div>

        <div className="lg:mx-20">
          <ScrollLink
            to="services-section"
            smooth={true}
            duration={500}
            className="block lg:inline-block text-primary mx-10 my-4 cursor-pointer">
            Services
          </ScrollLink>
          <RouterLink
            to="/desktop"
            className="block mt-4 lg:inline-block lg:mt-0 mx-10 text-primary cursor-pointer">
            Products
          </RouterLink>
          <ScrollLink
            to="Aboutus-section"
            smooth={true}
            duration={500}
            className="block lg:inline-block text-primary mx-10 my-4 cursor-pointer">
            About us
          </ScrollLink>
          <button
            onClick={togglePopup}
            className="inline-flex items-center py-2 px-6 mx-4 text-primary border border-1 border-primary rounded-full bg-buttonbg hover:bg-hover-buttonbg applydemo">
            Apply for a Demo
          </button>
          <ScrollLink
            to="Contactus-section"
            smooth={true}
            duration={500}
            className="inline-flex items-center bg-amber-500 py-2 px-5 mx-4 text-primary bg-transparent border border-1 border-primary rounded-full hover:bg-buttonbg hover:text-primary hover:border-primary"
            style={{ cursor: 'pointer' }}>
            Contact us
          </ScrollLink>
        </div>

      </div>

      {/* Popup component */}
      {showPopup && <Popup onClose={togglePopup} />}

    </nav>
  );
}
export default Header;
