import React from 'react';
import rightbanner from '../../assets/frontend/images/section2rightbanner.svg';

const Section2 = () => {
    return (
        <div className="p-6 md:p-10 lg:p-20 section2 text-center relative" id='Aboutus-section'>
            <div className="mx-auto md:mx-6 lg:mx-20">
                <div className="flex flex-col md:flex-row items-center justify-around lg:mx-64 md:mx-12">
                    <div className="w-3/4 md:w-1/3 md:order-1"> 
                        <img src={rightbanner} alt="Image Description" className="max-w-full h-auto" />
                    </div>
                    <div className="w-2/3 md:w-2/3 md:px-2 lg:px-16 md:order-3"> {/* Text Column */}
                        <h3 className="section2headtxt mb-2">Why choose us?</h3>
                        <div className="section2subtitle mb-4">
                            <span className='pl-4'>With</span> the rapid pace of digital innovation and big data availability, fast development makes it harder and challenging to follow and prioritize.
                            Business Executives understand the importance and the need of Digital Innovation, Artificial Intelligent and Big Data. But often, they don't know where to start and what is possible.
                        </div>
                        <div className="section2subtitle">
                            <span className='pl-4'>ESMR</span> are here to help as the expert partner to start the journey by combining deep business and industry insight with digital innovation and big data collection.
                            Our teams of creative, industry and technology professional advisers will help you accelerate the impact digital solutions can have on your business.
                        </div>
                    </div>
                </div>

                <div className="mt-10 md:mt-20">
                    {/* Text under both columns */}
                    <p className="section2subtxt">
                        Farewell to Distracted Data, Welcome To Data-Driven Calmness
                    </p>
                </div>
            </div>

            {/* Stars */}
            <div className="sestar1"></div>
            <div className="sestar2"></div>
            <div className="sestar3"></div>
            <div className="sestar4"></div>
        </div>
    );
};

export default Section2;
